/**
 * Twiddly dee twiddly dumb,
 * This is a file that had little use of my thumb.
 * - Blanksy
 */
(function ($, window, document, undefined) {
    'use strict';
    
    
    

    window.THF = window.THF || {};
    window.THF.Interface = window.THF.Interface || {};
    window.THF.Interface.SemanticStatementDropdown = function (container, options) {
        var current = {
            panel: 'statement-summary',
        }
        /**
         * CONSTANTS
         */
        var GOTO_DIRECTION_FORWARD = 1;
        var GOTO_DIRECTION_BACK = 1;

        /**
         * Default values for the code below to function.
         *
         * @type {{selectors: {parentElem: string, btnOption: string, panel: string}, data: {}}}
         */
        var defaults = {
            selectors: {
                //Module Parent
                parentElem: '.semantic-statement',
                panelWrapper: '.panel-wrapper',
                btnOption: 'a.option',
                panel: '.panel'
            },
            matrix: []
        };

        /**
         *
         */
        options = $.extend(true, {}, defaults, options);

        /**
         * Breadcrumb for the back button, this is pushed and popped
         * as the panels are navigated.
         *
         * @type {Array}
         */
        var panelPosition = [];

        /**
         *
         * @type {{location: string, age: string, membership-type: string, cover-type: string}}
         */
        var data = {
            'location': '',
            'age': '',
            'membership-type': '',
            'cover-type': ''
        };

        /**
         * Declare DOM elements
         */
        var $parent = $(container);
        var $panelWrapper = $container(options.selectors.panelWrapper);
        var $allPanels = $container('.panel');
        var $panel = {
            'start': $container('.panel.start'),
            'location': $container('.panel.location'),
            'age': $container('.panel.age'),
            'membership-type': $container('.panel.membership-type'),
            'cover-type': $container('.panel.cover-type'),
            'statement-summary': $container('.panel.statement-summary')
        };
        var $dropdown = {
            'location': $panelElem('statement-summary', 'select[name="location"]'),
            'age': $panelElem('statement-summary', 'select[name="age"]'),
            'membership-type': $panelElem('statement-summary', 'select[name="membership-type"]'),
            'cover-type': $panelElem('statement-summary', 'select[name="cover-type"]')
        };


        /**
         * Helper function to return a jquery object, which is a child of 'parentElem'
         *
         * @param {string} selector
         * @returns {*|HTMLElement}
         */
        function $container(selector) {
            return $(selector, $parent);
        }

        /**
         *
         * @param {string} panel
         * @param {string} selector
         * @returns {*|HTMLElement}
         */
        function $panelElem(panel, selector) {
            return $(selector, $container('.panel.' + panel));
        }


        /**********************************
         // Event Binding
         **********************************/
        /**
         * Binds the events to the relevant DOM elements.
         *
         * Note: The .off() is necessary as it allows for the
         *       rebinding of the events when new options are added.
         */
        var $buttonQuote;
        var bindEvents = function () {
            $buttonQuote = $container('.btn-quote');
            $buttonQuote.off('click').on('click', eventButtonQuote);
            
            $dropdown.age.off('change', eventDropdownUpdate).on('change', eventDropdownUpdate);
            $dropdown.location.off('change', eventDropdownUpdate).on('change', eventDropdownUpdate);
            $dropdown['membership-type'].off('change', eventDropdownUpdate).on('change', eventDropdownUpdate);
            $dropdown['cover-type'].off('change', eventDropdownUpdate).on('change', eventDropdownUpdate);


        };

        var eventDropdownUpdate = function (e) {
            var doAction = false;
            var name = $(this).attr('name');
            var value = $(this).val();

            if( name == 'location' ) {
                doAction = true;
                data.location = value;
            }
            else if( name == 'age' ) {
                doAction = true;
                data.age = parseInt(value,10);
                //Reset the default dropdown value
                data['cover-type'] = '';
            }
            else if( name == 'membership-type' ) {
                doAction = true;
                data['membership-type'] = value;
                //Reset the default dropdown value
                data['cover-type'] = '';
            }
            else if( name == 'cover-type' ) {
                doAction = true;
                data['cover-type'] = value.toString();
            }
            if( doAction ) {
                populateStatementSummary();
                setLinkHref();
            }

            THF.Utility.DatalayerTracking.track('SemanticStatement', {
                type: options.moduleMode,
                action: 'dropdown-select',
                panel: current.panel,
                actionCategory: name,
                actionValue: getAnalyticsSelectLabelValue($(this))
            });
        };

        // Chrome was cancelling the redirect request so instead of redirecting via JS update the href attribute.
        var setLinkHref = function() {
            var state = data.location;
            var membershipType = data['membership-type'];
            var coverType = data['cover-type'];
            var hospitalCover = '';
            var ancillaryCover = '';

            //If the cover-type and state dropdowns are empty/default copy, then reset default href.
            if(coverType === '' || state === ''){
                $buttonQuote.attr("href", "#");
            }
            
            if(
                state !== '' &&
                membershipType !== '' &&
                coverType !== ''
            ) {
                
                var matches = coverType.match(/^[0-9]+\-([a-zA-Z0-9]*)\|([a-zA-Z0-9]*)?$/);
                if (matches.length === 3) {
                    hospitalCover = matches[1];
                    ancillaryCover = matches[2];
                }

                $buttonQuote.attr("href", THF.Utility.Location.navigateToLiveQuoteTool(state, membershipType, hospitalCover, ancillaryCover, data.age));    
            }

            
        };

        var eventButtonQuote = function(e) {
            THF.Utility.DatalayerTracking.track('SemanticStatement', {
                type: options.moduleMode,
                action: 'button-click',
                panel: current.panel,
                actionCategory: 'Get a quote now',
                actionValue: getAnalyticsQuoteLabelValue()
            });
        };

        function getAnalyticsQuoteLabelValue() {
            var selectedValues = [];
            $('select', $panel['statement-summary']).each(function () {
                selectedValues.push($(':selected', this).text());
            });

            return selectedValues.join(' | ');
        }
        
        function getAnalyticsSelectLabelValue(select) {
            return $(':selected', select).text();
        }

        /**
         * When clicking the get stared link this happens.
         *
         * @param e An event object that is returned from jQuery Events.
         */
        var eventGetStarted = function (e) {
            e.preventDefault();
            panelPosition.push('start');
            gotoPanel('location');
        };

        /**
         * When clicking on any of the back buttons this event is triggered.
         *
         * @param e An event object that is returned from jQuery Events.
         */
        var eventButtonBack = function (e) {
            e.preventDefault();
            var panel = panelPosition.pop();
            gotoPanel(panel, GOTO_DIRECTION_BACK);
        };

        /**
         * When clicking on any of the options in the panels before the Semantic
         * statement this is the event that pushes you forwards.
         *
         * @param e An event object that is returned from jQuery Events.
         */
        var eventButtonOption = function (e) {
            e.preventDefault();

            var element = $(this);

            var type = element.data('type');
            var value = element.data('value');
            var text = $('span', element).text();

            // Ensure we have the panel that we are saying we are selecting.
            var validOption = (typeof data[type] !== 'undefined');
            if (validOption) {
                data[type] = value;
                var eventAction = null;
                var panel = '';
                switch (type) {
                    case 'location':
                        eventAction = 'Step 2';
                        panel = 'age';
                        break;
                    case 'age':
                        eventAction = 'Step 3';
                        panel = 'membership-type';
                        break;
                    case 'membership-type':
                        eventAction = 'Step 4';
                        panel = 'cover-type';
                        break;
                    case 'cover-type':
                        eventAction = 'Step 5';
                        panel = 'statement-summary';
                        break;
                }

                if (eventAction !== null) {
                    THF.Utility.DatalayerTracking.track('SemanticStatement', {
                        type: options.moduleMode,
                        action: 'button-click',
                        panel: panel,
                        actionCategory: null,
                        actionValue: text
                    });
                }

                if (_.indexOf(panelPosition, type) == -1) {
                    panelPosition.push(type);
                }
                gotoPanel(panel);
            }
            else {
                // console.log('ERROR: Invalid data type: ' + type);
            }
        };

        /**
         * Selects the relevant cover types to display based
         * on the selections of age and membership-type.
         */
        var filteredCoverTypeData = function () {
            /**
             * We only want to get cover types that are of the
             * age range and that are of the correct membership type.
             */
            var results = _.filter(options.matrix, function (item) {

                var result = true;
                // Only get the cover types which are related to the selected membership type.
                if (data['membership-type'] != item['membership-type']) {
                    result = false;
                }

                // Filter the cover types by the ages that are in the array.
                if (result === true && _.indexOf(item.age, parseInt(data.age, 10)) == -1) {
                    result = false;
                }

                // Alrighty then, lets return the result here.
                return result;
            });
            return results;
        };

        var coverTypeValueLength = 0;
        /**
         * Build the cover type value from the cover type object passed in.
         *
         * @param {object} coverType
         * @return {string}
         */
        var coverTypeValue = function (coverType) {
            return (coverTypeValueLength++) + '-' + coverType.hospitalCover + '|' + coverType.ancillaryCover;

        };

        /**
         * Iterate over the cover types and render the markup
         * that needs to be injected.
         *
         * There is likely a better way of doing this, but this works
         * for now.
         */
        var populateCoverTypePanel = function () {
            coverTypeValueLength = 0;
            var coverTypes = filteredCoverTypeData();
            if (coverTypes.length > 0) {
                var coverType;
                var htmlCoverTypes = $('<div>');
                for (var i = 0; i < coverTypes.length; i++) {
                    coverType = coverTypes[i];

                    var htmlCoverTypeText = $('<div class="inner">').html(coverType.label);
                    var htmlCoverTypeLink = $('<a>')
                        .attr('href', '#')
                        .attr('data-type', 'cover-type')
                        .attr('data-value', coverTypeValue(coverType))
                        .addClass('option')
                        // .append(htmlCoverTypeTextWrap)
                        .append(htmlCoverTypeText);
                    htmlCoverTypes.append(htmlCoverTypeLink);
                }

                $panelElem('cover-type', '.panel-options').html(htmlCoverTypes.html());
                bindEvents();
            }
        };

        /**
         * Iterate over the cover types and populate any dropdown's and values that are needed.
         */
        var populateStatementSummary = function () {
            coverTypeValueLength = 0;

            //// Set the value for the State Dropdown
            $dropdown.location.val(data.location);

            // Set the value for the Age Dropdown
            $dropdown.age.val(data.age);

            // Set the value for the Membership Type Dropdown
            $dropdown['membership-type'].val(data['membership-type']);

            // Cover Types Dropdown
            var coverTypes = filteredCoverTypeData();
            $dropdown['cover-type'].html('');

            // Always add the default "type of cover" option first
            var defaultOption = $('<option>')
                .val('')
                .html('type of cover');
            $dropdown['cover-type'].append(defaultOption);
            
            if (coverTypes.length > 0) {
                var coverType;
                var option;
                for (var i = 0; i < coverTypes.length; i++) {
                    coverType = coverTypes[i];
                    option = $('<option>')
                        .val(coverTypeValue(coverType))
                        .html(coverType.label);
                    $dropdown['cover-type'].append(option);
                }
            }

            if( $('option[value="'+data['cover-type']+'"]', $dropdown['cover-type']).length > 0 ) {
                $dropdown['cover-type'].val(data['cover-type']);
            }
            else {
                $('option:eq(0)', $dropdown['cover-type']).prop('selected', true);
            }

            // initialise the dropdowns
            $container('select').selectpicker('refresh');
        };

        /**
         * Opens the panel by its specified class name.
         *
         * NOTE: This panel needs to be listed in the $panel
         * selector array in the dom selectors section of
         * this file.
         *
         * @param {string} panelName A panel listed in the $panel object that can be loaded.
         * @param {number} direction The direction that the panel will navigate (use GOTO_DIRECTION_* constants above.;)
         */
        var gotoPanel = function (panelName, direction) {

            direction = ((typeof direction !== 'undefined') ? direction : GOTO_DIRECTION_FORWARD);
            if (typeof $panel[panelName] !== 'undefined') {

                /*
                Add / remove class based on panel position -
                required to achieve new design
                 */
                if(panelPosition.length === 0 ) {

                    $parent.addClass('module-start');
                    $parent.removeClass('header-hidden');

                } else if(panelPosition.length === 4){

                    $parent.addClass('header-hidden')

                } else {

                    $parent.removeClass('module-start');
                    $parent.removeClass('header-hidden');
                }

                if(panelName == 'location') {

                }

                if(panelName == 'age') {

                }

                if(panelName == 'membership') {

                }

                if (panelName == 'cover-type') {
                    populateCoverTypePanel();
                }
                else if (panelName == 'statement-summary') {
                    populateStatementSummary();
                    setLinkHref();
                }

                hidePanel($allPanels.not($panel[panelName]), direction);
                showPanel($panel[panelName], direction);
                setWrapperAttr(panelPosition.length);
            }
        };

        /**
         * Hides the panel behind the element.
         *
         * @param elem
         * @param direction
         */
        var hidePanel = function (elem) {
            elem.removeClass('active');
        };

        /**
         * Shows the panel behind the element.
         *
         * @param elem
         * @param direction
         */
        var showPanel = function (elem) {
            elem.addClass('active');
        };


        /**
         * Sets attribute on the panel wrapper to inform which panel is active
         * Used for CSS hooks to update the height accordingly
         *
         * @param currentPanel
         */
        var setWrapperAttr = function (currentPanel) {
            $panelWrapper.attr('data-current-panel', currentPanel);
        };
        /**********************************
         // Simple constructor
         **********************************/
        /**
         * Initialisation
         *
         * @private
         */
        var __construct = function () {
            if ($parent.length > 0) {
                bindEvents();
                
                THF.Utility.DatalayerTracking.trackIfElementInViewport($parent, 'SemanticStatement', {
                    type: options.moduleMode,
                    action: 'scroll-into-view',
                    panel: null,
                    actionCategory: null,
                    actionValue: null
                });
            }
        };


        __construct();
    };


})(jQuery, window, document);
