var THF = THF || {};
THF.Interface = THF.Interface || {};

(function($) {
    'use strict';

    THF.Interface.ContactForm = function(options) {
        var defaults = {
            formModal: '.module-contact-form',
        };

        options = $.extend(true, {}, defaults, options);

        function init() {
            var $formModal = $(options.formModal);
            var $form = $formModal.find('.form-wrapper');
            
            if ($form.length) {

                document.getElementById('submitBtn').addEventListener('click', function (e) {
                    
                    if (!validateForm()) {
                        DSTAPI.track('event', {
                            category: 'Contact Form Module',
                            action: 'Form Submission Failed',
                            label: 'Submit Button'
                        });
                        return;
                    }
                    
                    // Collect form data
                    var queryParams = {
                        firstName: $('#firstName').val().trim(),
                        lastName: $('#lastName').val().trim(),
                        email: $('#email').val().trim(),
                        isMember: $('#checkbox').is(':checked') ? "Y" : "N",
                        mobilePhone: $('#phoneType').val() === 'mobile' ? $('#phoneNumber').val().trim() : '',
                        homePhone: $('#phoneType').val() === 'home' ? $('#phoneNumber').val().trim() : '',
                        workPhone: $('#phoneType').val() === 'work' ? $('#phoneNumber').val().trim() : ''
                    };

                    // Send the form data as JSON
                    $.ajax({
                        url: '/api/v1/leadCaptureForm',
                        type: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify(queryParams),  
                        success: function (response) {
                            DSTAPI.track('event', {
                                category: 'Contact Form Module',
                                action: 'Form Submission Passed',
                                label: 'Submit Button'
                            });
                           setCookies(); 
                        },
                        error: function (error) {
                            DSTAPI.track('event', {
                                category: 'Contact Form Module',
                                action: 'Form Submission Failed',
                                label: 'Submit Button'
                            });
                            $('#form-error-msg').show();
                        }
                    });
                    
                });


                document.getElementById('laterBtn').addEventListener('click', function (e) {
                    DSTAPI.track('event', {
                        category: 'Contact Form Module',
                        action: 'Skip Button Clicked',
                        label: 'Skip Button'
                    });
                    setCookies();
                });
                
                // Allow only numbers
                $('#phoneNumber').on('input', function() {
                    this.value = this.value.replace(/[^0-9]/g, ''); 
                });
            }
        }

        function validateField($input) {
            var isValid = true;
            var $errorMessage = $input.next('.input-error-message');

            // Check if the field is empty
            if (!$input.val().trim()) {
                isValid = false;
                $errorMessage.show();
            } else {
                $errorMessage.hide();
            }

            // Allow only numbers
            $('#phoneNumber').on('input', function() {
                this.value = this.value.replace(/[^0-9]/g, ''); 
            });

            // Validate max length for first name and last name
            if ($input.attr('id') === 'firstName' && $input.val().length > 16) {
                isValid = false;
                $errorMessage.text('First name should be at most 16 characters long.').show();
            } else if ($input.attr('id') === 'lastName' && $input.val().length > 25) {
                isValid = false;
                $errorMessage.text('Last name should be at most 25 characters long.').show();
            }

            // Additional email validation
            if ($input.attr('id') === 'email') {
                var emailPattern = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z_])*@(([0-9a-zA-Z])+([-\w]*[0-9a-zA-Z])*\.)+[a-zA-Z]{2,9})$/;
                if ($input.val().trim() && !emailPattern.test($input.val().trim())) {
                    isValid = false;
                    $errorMessage.text('Please enter a valid email address.').show();
                }
            }

            return isValid;
        }

        function setCookies(){
            var cookieData ={
                displayForm : false
            }
            Cookies.set('contact-form-module', cookieData, { expires: 1 });
                    location.reload();
        }

        function validatePhoneField() {
            var $phoneType = $('#phoneType');
            var $phoneNumber = $('#phoneNumber');
            var $phoneErrorMessage = $phoneType.closest('.input-container').find('.input-error-message');
            var phoneValue = $phoneNumber.val().trim();
            var phoneType = $phoneType.val()
            var phonePattern = /^[0-9]+$/;

            // Only validate if the phone type is selected
            if (!phoneType) {
                $phoneErrorMessage.text('Please select a phone type.').show();
                return false;
            }

            // Check if the phone number is empty if phone type is selected
            if (!phoneValue) {
                $phoneErrorMessage.text('Please enter a phone number.').show();
                return false;
            }

            // Check if the phone number consists of numbers only
            if (!phonePattern.test(phoneValue)) {
                $phoneErrorMessage.text('Phone number can only contain numbers.').show();
                return false;
            }

            // Check if the phone number is 10 digits
            if (phoneValue.length > 10) {
                $phoneErrorMessage.text('Phone number cannot be longer than 10 digits.').show();
            }

            if (phoneType === 'mobile') {
                // Validate mobile numbers (must start with '04' and be exactly 10 digits long)
                if (!/^04\d{8}$/.test(phoneValue)) {
                    $phoneErrorMessage.text('Please enter a valid mobile number (starting with 04 and exactly 10 digits).').show();
                    return false;
                }
            } else if (phoneType === 'home' || phoneType === 'work') {
                // Validate home/work numbers (must start with 02, 03, 07, or 08 and be exactly 10 digits long)
                if (!/^(02|03|07|08)\d{8}$/.test(phoneValue)) {
                    $phoneErrorMessage.text('Please enter a valid '+ phoneType +' phone number (starting with area code, numbers only, and must be 10 digits)').show();
                    return false;
                }
            }

            $phoneErrorMessage.hide();
            return true;
        }

        function validateForm() {
            var isValid = true;
            var $inputs = $('.form-wrapper input[required]');

            // Validate required inputs
            $inputs.each(function() {
                if (!validateField($(this))) {
                    isValid = false;
                }
            });

            // Validate phone fields only on form submission
            if (!validatePhoneField()) {
                isValid = false;
            }

            return isValid;
        }

        resetForm();

        function resetForm() {
            $('.error-msg').hide(); // Hide error messages
            $('.input-error').hide(); // Remove error style
        }

        init();

        return {};
    };

})(jQuery);
