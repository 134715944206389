var THF = THF || {};
THF.Interface = THF.Interface || {};

(function($) {
	'use strict';

	THF.Interface.UmbracoFormsCustomizations = function( options ) {

		var defaults = {
            umbracoFormSelector: '.umbraco-forms-form form',		
            bootstrapSelectCssClass: 'bootstrap-select',
            fieldValidationErrorCssClass: 'field-validation-error'			
		};

		options = $.extend(true, {}, defaults, options );

        // Umbraco Forms has a focus event, but doesn't work well with bootstrap select (selectpicker)
        // So, we are adding a mutation observer to detect validation errors and scrolling to the first invalid field if it is a bootstrap select
        function scrollToBootstrapSelect(){
        
            var forms = document.querySelectorAll(options.umbracoFormSelector);
  
            var observer = new MutationObserver(function (mutationRecords) {
                for (var i = 0; i < mutationRecords.length; i++) {
                    
                    var mutationRecord = mutationRecords[i];

                    // Check if the mutated element has a validation error class
                    if (mutationRecord.target.className === options.fieldValidationErrorCssClass) {
                        
                        var validationErrorEl = mutationRecord.target;
                        var formFieldElement = validationErrorEl.previousElementSibling;

                        // Traverse the previous siblings to find the bootstrap select element
                        while (formFieldElement) {
                            if (formFieldElement.classList.contains("bootstrap-select")) {                                
                                formFieldElement.scrollIntoView(); // Scroll to the bootstrap select element                               
                                break;
                            }
                            formFieldElement = formFieldElement.previousElementSibling;
                        }
                        break;
                    }
                }
            });
                    
            for (var i = 0; i < forms.length; i++) {
                var formEl = forms[i];                
                observer.observe(formEl, {
                    attributes: true,         // Watch for attribute changes
                    attributeFilter: ['class'], // Only observe changes to the `class` attribute
                    childList: false,          // Don't observe the addition or removal of child elements
                    characterData: false,      // Don't observe text changes
                    subtree: true              // Observe the entire subtree of the form
                });
            }
        }
		
		scrollToBootstrapSelect();

		//
		// Make some events accessible from global scope
		//
		return {
		};
	};


})(jQuery);
